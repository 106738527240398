import React, { createContext, useContext, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { failure } from "../utils/toast";
import { getHeaders } from "../utils";

// Initial state for authentication
const initialState = {
  user: null,
  isAuthenticated: !!localStorage.getItem(process.env.REACT_APP_USERTOKEN),
  login: { loading: false, errors: {} },
};

// Action types for authentication
const actionTypes = {
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT: "LOGOUT",
};

// Reducer function to handle authentication actions
const authReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_LOADING:
      return {
        ...state,
        login: {
          loading: action.payload,
        },
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        login: {
          loading: false,
          errors: {},
        },
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          loading: false,
          errors: action.payload,
        },
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

// Create a new context for authentication
const AuthContext = createContext();

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const userToken = localStorage.getItem(process.env.REACT_APP_USERTOKEN);
    if (userToken) loadUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUserInfo = () => {
    dispatch({ type: actionTypes.LOGIN_LOADING, payload: true });

    try {
      axios
        .get("/auth/user/me/", { headers: getHeaders()})
        .then((res) => {
          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.LOGIN_FAILURE,
            payload: err?.response?.data?.errors
              ? err.response.data.errors
              : {},
          });
          logout();
        });
    } catch (err) {
      // failure("Some error ocuurred while login! Please try again later.");
    }
  };

  // Function to login
  const login = (userData, cb) => {
    dispatch({ type: actionTypes.LOGIN_LOADING, payload: true });

    try {
      axios
        .post("/auth/login/", userData)
        .then((res) => {
          localStorage.setItem(
            process.env.REACT_APP_USERTOKEN,
            res.data?.key
          );
          window.dispatchEvent(new Event("auth_storage_updated"));
          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: res.data,
          });
          cb();
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.LOGIN_FAILURE,
            payload: err?.response?.data?.errors
              ? err.response.data.errors
              : {},
          });
          failure(err?.response?.data?.errors);
        });
    } catch (err) {
      failure("Some error ocuurred while login! Please try again later.");
    }
  };

  // Function to logout
  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_USERTOKEN);
    window.dispatchEvent(new Event("auth_storage_updated"));
    dispatch({ type: actionTypes.LOGOUT });
  };

  // Provide the context value to its children components
  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context easily in functional components
export const useAuth = () => {
  return useContext(AuthContext);
};
