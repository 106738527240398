import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EventProvider } from "./context/EventContext";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <EventProvider>
            <Routes />
            <ToastContainer />
          </EventProvider>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
