import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import classes from "./styles.module.scss";

export default function FullScreenLoading({ style }) {
  return (
    <div style={style} className={classes.loader_container}>
      <ScaleLoader color={"#3c096c"} />
    </div>
  );
}
