import React from "react";
import { Route, Switch } from "react-router-dom";
import * as URLS from "./urls";
import withSuspense from "../utils/withSuspense";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";

const Home = React.lazy(() => import("../pages/Home/Home"));
const AddEvent = React.lazy(() => import("../pages/AddEvent/AddEvent"));
const EventPage = React.lazy(() => import("../pages/EventPage/EventPage"));
const TermsAndConditionsPage = React.lazy(() => import("../pages/others/TermsAndConditions/TermsAndConditions"));
const HowToPostAnEvent = React.lazy(() => import("../pages/others/HowToPostAnEvent/HowToPostAnEvent"));
const Newsletter = React.lazy(() => import("../pages/others/Newsletter/Newsletter"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const DashboardMain = React.lazy(() => import("../pages/Dashboard"));
const NotFoundPage = React.lazy(() => import("../pages/others/NotFound"));

const Routes = () => {

  return (
    <Switch>
      {/* Auth */}
      <GuestRoute
        exact
        path={URLS.HOME}
        component={withSuspense(Home)}
      />
      <GuestRoute
        exact
        path={URLS.ADD_EVENT}
        component={withSuspense(AddEvent)}
      />
      <PrivateRoute
        exact
        path={URLS.DASHBOARD_MAIN}
        component={withSuspense(DashboardMain)}
      />
      <GuestRoute
        exact
        path={URLS.EVENT_PAGE}
        component={withSuspense(EventPage)}
      />

      <PrivateRoute
        exact
        path={URLS.TERMS_AND_CONDITIONS}
        component={withSuspense(TermsAndConditionsPage)}
      />

      <Route
        exact
        path={URLS.HOW_TO_POST_AN_EVENT}
        component={withSuspense(HowToPostAnEvent)}
      />

      <Route
        exact
        path={URLS.NEWSLETTER}
        component={withSuspense(Newsletter)}
      />
      <Route
        exact
        path={URLS.LOGIN_PAGE}
        component={withSuspense(Login)}
      />

      <Route component={withSuspense(NotFoundPage)} />
    </Switch>
  );
};

export default Routes;
